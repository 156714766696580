import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	ICssFetcher,
	IPageResourceFetcher,
	PageResourceFetcherSymbol,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'

export const OoiCssFetcher = withDependencies<ICssFetcher>(
	[PageResourceFetcherSymbol, ViewerModelSym],
	(pageResourceFetcher: IPageResourceFetcher, viewerModel: ViewerModel) => ({
		id: 'ooi_css',
		fetch: (pageId) => {
			const { enabled, ooiVersions } = viewerModel.siteAssets.modulesParams.ooiCss || {}
			return enabled && ooiVersions
				? pageResourceFetcher.fetchResource(pageId, 'ooiCss', 'enable')
				: Promise.resolve('')
		},
	})
)
