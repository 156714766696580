import type { CreateTpaWidgetNative } from '../types'

const getFactory: (isLoadable: boolean) => CreateTpaWidgetNative = (isLoadable = false) => {
	if (process.env.browser) {
		return require('./tpaWidgetNativeClient').createTpaWidgetNative
	} else {
		return isLoadable
			? require('./tpaWidgetNativeSSRLoadable').createTpaWidgetNative
			: require('./tpaWidgetNativeSSR').createTpaWidgetNative
	}
}

export const getCreateTpaWidgetNativeFactory = getFactory
