import _ from 'lodash'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPropsStore, Props, SdkHandlersProvider, SetControllerProps } from '@wix/thunderbolt-symbols'

export default withDependencies(
	[Props],
	(propsStore: IPropsStore): SdkHandlersProvider<{ setControllerProps: SetControllerProps }> => {
		return {
			getSdkHandlers() {
				return {
					setControllerProps(controllerCompId, controllerDataProps, functionNames, invokeFunction) {
						functionNames.forEach((functionName) =>
							_.set(controllerDataProps, functionName, (...args: any) =>
								invokeFunction(functionName, args)
							)
						)
						propsStore.update({ [controllerCompId]: controllerDataProps })
					},
				}
			},
		}
	}
)
