import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { ILogger, LoggerSymbol, ViewerModel, ViewerModelSym } from '@wix/thunderbolt-symbols'
import type { OOIComponentLoader } from './types'
import { MODULE_URL } from './constants'

async function loadRequireJS(moduleRepoUrl: string = MODULE_URL, logger: ILogger) {
	// since react umd bundles do not define named modules, we must load react before loading requirejs.
	// further details here: https://requirejs.org/docs/errors.html#mismatch
	// requirejs will be hopefully removed once ooi comps will be consumed as comp libraries.
	await window.reactAndReactDOMLoaded
	await new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = `${moduleRepoUrl}/requirejs-bolt@2.3.6/requirejs.min.js`
		script.onload = resolve
		script.onerror = reject
		document.head.appendChild(script)
	})
	// @ts-ignore
	window.define('lodash', [], () => _)
	// @ts-ignore
	window.define('reactDOM', [], () => ReactDOM)
	// @ts-ignore
	window.define('react', [], () => React)

	// @ts-ignore
	window.requirejs.onError = (error) => {
		const { requireModules, requireType } = error
		logger.captureError(error, {
			tags: { feature: 'ooi', methodName: 'requirejs.onError' },
			extra: { requireModules, requireType },
		})
	}
}

// eslint-disable-next-line prettier/prettier
export default withDependencies([ViewerModelSym, LoggerSymbol], ({ siteAssets }: ViewerModel, logger: ILogger): OOIComponentLoader => {
		let waitForRequireJsToLoad: Promise<unknown> | null = null

		const load = (url: string) =>
			new Promise(async (resolve, reject) => {
				waitForRequireJsToLoad =
					waitForRequireJsToLoad || loadRequireJS(siteAssets.clientTopology.moduleRepoUrl, logger)
				await waitForRequireJsToLoad
				__non_webpack_require__([url], (module: any) => resolve(module), reject)
			})

		return {
			loadComponent(componentUrl: string) {
				return load(componentUrl).then((module: any) => module?.default?.component) as Promise<
					React.ComponentType<any>
				>
			},
			loadModule(url: string) {
				return load(url) as Promise<Record<string, any>>
			},
		}
	}
)
